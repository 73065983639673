import React, { useContext, useMemo } from "react";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import xemelgoStyle from "../../../../styles/variable";
import useCheckOutTableStateContext from "../check-out-table-state-context";

const TITLE = "title";
const ACTIONS_MAP = "actionsMap";
const TABLE_SECTIONS = "tableSections";
const TOP_PANEL_ATTRIBUTES = "topPanelAttributes";
const SIDE_PANEL_ATTRIBUTES = "sidePanelAttributes";
const API_URL = "apiUrl";
const ITEM_TYPE_QUERY_ATTRIBUTES = "itemTypeQueryAttributes";
const REPORT = "report";
const QUERY_FREQUENCY_IN_SECONDS = "queryFrequencyInSeconds";
const UPC_NUMBER_OF_CHARACTER_TO_TRIM = "upcNumberOfCharactersToTrim";
const INACTIVITY_THRESHOLD_IN_MINUTES = "inactivityThresholdInMinutes";
const READER_POWER_TO_SET = "readerPowerToSet";
const HAS_SGTIN_TAGS = "hasSgtinTags";
const ENABLED_SIMPLE_TAG_GROUPING = "enabledSimpleTagGrouping";
const ROW_BACKGROUND_COLOR = "rowBackgroundColor";
const HIDE_CONFIRM_BUTTON = "hideConfirmButton";
const HIDE_CLEAR_BUTTON = "hideClearButton";
const UPDATE_READER_STATE_ON_CLEAR = "updateReaderStateOnClear";
const TABLE_TEXT_STYLE = "tableTextStyle";
const ITEM_CLASS_FILTERS = "itemClassFilters";
const DEFAULT_LOCATION_IDENTIFIER_FILTER_BY = "defaultLocationIdentifierFilterBy";
const LOCATION_OPTION_CATEGORY = "locationOptionCategory";
const READER_LOCATION_OPTION_CATEGORY = "readerLocationOptionCategory";

const CheckOutTableConfigContext = React.createContext();

const SOLUTION_ID = "kiosk";

const initialState = {
  title: "Kiosk",
  actionsMap: {
    "check-out": {
      id: "check-out",
      label: "Start Checking Out"
    }
  },
  tableSections: [
    {
      attributes: [
        {
          id: "rowCount",
          label: ""
        }
      ],
      label: "#"
    },
    {
      attributes: [
        {
          id: "identifier",
          label: ""
        }
      ],
      label: "UPC"
    },
    {
      attributes: [
        {
          id: "qty"
        }
      ],
      label: "Qty"
    }
  ],
  topPanelAttributes: [
    {
      id: "location",
      label: "Location",
      type: "dropdownWithOptionsFromAPI",
      isRequired: true
    },
    {
      id: "readerLocation",
      label: "Read Point",
      type: "dropdownWithOptionsFromAPI",
      isRequired: true
    }
  ],
  sidePanelAttributes: [],
  defaultLocationIdentifierFilterBy: "",
  apiUrl: "https://zcb5qribob.execute-api.us-west-2.amazonaws.com/dev",
  itemTypeQueryAttributes: ["identifier"],
  report: {
    additionalSubmitAttributesMap: {},
    customSubmitActionsMap: {
      endTrackingSession: false,
      consumeItem: true,
      flipHasExitState: true
    }
  },
  queryFrequencyInSeconds: 3,
  upcNumberOfCharactersToTrim: 2,
  inactivityThresholdInMinutes: 30,
  readerPowerToSet: 25,
  hasSgtinTags: false,
  enableSimpleTagGrouping: false,
  rowBackgroundColor: "transparent",
  hideConfirmButton: false,
  hideClearButton: false,
  updateReaderStateOnClear: true,
  tableTextStyle: {
    fontFamily: `"Avenir", sans-serif`,
    fontWeight: 700,
    fontSize: 15,
    color: xemelgoStyle.theme.TEXT_PRIMARY,
    backgroundColor: xemelgoStyle.theme.APP_WHITE
  },
  itemClassFilters: [],
  locationOptionCategory: "Department",
  readerLocationOptionCategory: "Department"
};

export const useCheckOutTableConfigContext = () => {
  return useContext(CheckOutTableConfigContext);
};

export const CheckOutTableConfigContextProvider = ({ children }) => {
  const { selectedAction } = useCheckOutTableStateContext();

  const checkOutTableConfigProvider = useAppConfigProvider(SOLUTION_ID);

  const {
    title,
    actionsMap,
    tableSections,
    topPanelAttributes,
    sidePanelAttributes,
    apiUrl,
    itemTypeQueryAttributes,
    queryFrequencyInSeconds,
    upcNumberOfCharactersToTrim,
    inactivityThresholdInMinutes,
    readerPowerToSet,
    hasSgtinTags,
    enableSimpleTagGrouping,
    rowBackgroundColor,
    hideConfirmButton,
    hideClearButton,
    updateReaderStateOnClear,
    tableTextStyle,
    itemClassFilters,
    reportConfig,
    defaultLocationIdentifierFilterBy,
    locationOptionCategory,
    readerLocationOptionCategory
  } = useMemo(() => {
    const { config = {} } = checkOutTableConfigProvider;
    const { actionsMap: newActionsMap = {} } = config;
    const actionConfig = newActionsMap[selectedAction] || {};

    const getConfigValue = (key) => {
      return actionConfig[key] ?? config[key] ?? initialState[key];
    };

    return {
      title: getConfigValue(TITLE),
      actionsMap: getConfigValue(ACTIONS_MAP),
      tableSections: getConfigValue(TABLE_SECTIONS),
      topPanelAttributes: getConfigValue(TOP_PANEL_ATTRIBUTES),
      sidePanelAttributes: getConfigValue(SIDE_PANEL_ATTRIBUTES),
      apiUrl: getConfigValue(API_URL),
      itemTypeQueryAttributes: getConfigValue(ITEM_TYPE_QUERY_ATTRIBUTES),
      queryFrequencyInSeconds: getConfigValue(QUERY_FREQUENCY_IN_SECONDS),
      upcNumberOfCharactersToTrim: getConfigValue(UPC_NUMBER_OF_CHARACTER_TO_TRIM),
      inactivityThresholdInMinutes: getConfigValue(INACTIVITY_THRESHOLD_IN_MINUTES),
      readerPowerToSet: getConfigValue(READER_POWER_TO_SET),
      hasSgtinTags: getConfigValue(HAS_SGTIN_TAGS),
      enableSimpleTagGrouping: getConfigValue(ENABLED_SIMPLE_TAG_GROUPING),
      rowBackgroundColor: getConfigValue(ROW_BACKGROUND_COLOR),
      hideConfirmButton: getConfigValue(HIDE_CONFIRM_BUTTON),
      hideClearButton: getConfigValue(HIDE_CLEAR_BUTTON),
      updateReaderStateOnClear: getConfigValue(UPDATE_READER_STATE_ON_CLEAR),
      tableTextStyle: getConfigValue(TABLE_TEXT_STYLE),
      itemClassFilters: getConfigValue(ITEM_CLASS_FILTERS),
      reportConfig: getConfigValue(REPORT),
      defaultLocationIdentifierFilterBy: getConfigValue(DEFAULT_LOCATION_IDENTIFIER_FILTER_BY),
      locationOptionCategory: getConfigValue(LOCATION_OPTION_CATEGORY),
      readerLocationOptionCategory: getConfigValue(READER_LOCATION_OPTION_CATEGORY)
    };
  }, [checkOutTableConfigProvider, selectedAction]);

  return (
    <CheckOutTableConfigContext.Provider
      value={{
        title,
        actionsMap,
        tableSections,
        topPanelAttributes,
        sidePanelAttributes,
        apiUrl,
        itemTypeQueryAttributes,
        queryFrequencyInSeconds,
        upcNumberOfCharactersToTrim,
        inactivityThresholdInMinutes,
        readerPowerToSet,
        hasSgtinTags,
        enableSimpleTagGrouping,
        rowBackgroundColor,
        hideClearButton,
        hideConfirmButton,
        updateReaderStateOnClear,
        tableTextStyle,
        itemClassFilters,
        reportConfig,
        defaultLocationIdentifierFilterBy,
        locationOptionCategory,
        readerLocationOptionCategory
      }}
    >
      {children}
    </CheckOutTableConfigContext.Provider>
  );
};
