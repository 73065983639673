import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Event as CalendarIcon } from "@material-ui/icons";
import Skeleton from "react-loading-skeleton";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import { FEATURES, SOLUTIONS } from "../../../../data/constants";
import { getFormattedDate, TIME_IN_MS } from "../../../../common/Utilities";
import { useXemelgoAppsyncClient } from "../../../../services/xemelgo-appsync-service";
import ProgressGraph from "../../../../components/RouteTracker/ProgressGraph";
import PaginatedListTableWithTypes from "../../../../components/paginated-list-table-with-types";
import DateRangePicker from "../../../../components/data-range-picker";
import { DEFAULT_ROUTE_TABLE_HEADERS } from "./data/constants";
import {
  ALL_TIME_ID,
  ALL_TIME_LABEL,
  QUICK_FILTER_OPTIONS
} from "../../../../components/data-range-picker/data/constants";
import Style from "./AssetRouteFeature.module.css";
import useOnClickOutside from "../../../../hooks/use-on-click-outside";
import StatusPopupComponent from "../../../../components/status-popup-component";
import { STATUS_OPTIONS } from "../../../../components/status-popup-component/data/constants";

export const AssetRouteFeature = ({ itemIdentifier }) => {
  const assetAppsyncClient = useXemelgoAppsyncClient().getAssetClient();

  const detailsPageConfig = useAppConfigProvider(SOLUTIONS.ASSET).getValue(FEATURES.DETAILS_PAGE, "object") || {};
  const {
    defaultRouteTimeframeMs = TIME_IN_MS.WEEKS,
    timeFormat = "MMM D, YYYY h:mm A",
    routeTableHeaders = DEFAULT_ROUTE_TABLE_HEADERS,
    dateRangeQuickFilterOptions = QUICK_FILTER_OPTIONS
  } = detailsPageConfig;

  const popupRef = useRef(null);
  const [nowTimestamp] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date(nowTimestamp - defaultRouteTimeframeMs));
  const [endDate, setEndDate] = useState(new Date(nowTimestamp));
  const [assetRoute, setAssetRoute] = useState([]);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [isAllTimeSelected, setIsAllTimeSelected] = useState(false);
  const [fetchRouteFailed, setFetchRouteFailed] = useState(false);

  useOnClickOutside(popupRef, () => {
    setShowDateRangePicker(false);
  });

  useEffect(() => {
    fetchAssetRoute(startDate.getTime(), endDate.getTime());
  }, []);

  const fetchAssetRoute = async (startTimestamp, endTimestamp) => {
    setIsLoading(true);
    setFetchRouteFailed(false);

    let prevStep = {};

    try {
      const isEndDateNow = endTimestamp >= nowTimestamp || !endTimestamp;
      const newAssetRoute = (await assetAppsyncClient.queryAssetRoute(itemIdentifier, startTimestamp, endTimestamp))
        .reverse()
        .map((step) => {
          const status = {
            done: true,
            processing: !prevStep.startDate
          };

          let timeToNext;
          if (prevStep.startDate) {
            timeToNext = prevStep.startDate - step.endDate;
          }
          prevStep = step;

          return {
            id: step.location.name,
            startDate: step.startDate,
            endDate: step.endDate,
            duration: isEndDateNow ? step.duration : undefined,
            timeToNext,
            route: step.location.name,
            ...status
          };
        });

      setAssetRoute(newAssetRoute);
    } catch (error) {
      setAssetRoute([]);
      setFetchRouteFailed(true);
    }

    setIsLoading(false);
  };

  const timeRangeText = useMemo(() => {
    if (isAllTimeSelected) {
      return (
        dateRangeQuickFilterOptions.find((option) => {
          return option.id === ALL_TIME_ID;
        })?.label || ALL_TIME_LABEL
      );
    }

    const isEndDateNow = endDate.getTime() >= nowTimestamp;
    const timeDifference = endDate.getTime() - startDate.getTime();

    const quickSelectOption = dateRangeQuickFilterOptions.find((option) => {
      return option.value === timeDifference;
    });

    return isEndDateNow && quickSelectOption
      ? quickSelectOption.label
      : `${getFormattedDate(startDate, timeFormat)} - ${getFormattedDate(endDate, timeFormat)}`;
  }, [startDate, endDate, isAllTimeSelected]);

  const progressGraphTitle = useMemo(() => {
    if (assetRoute.length && (isAllTimeSelected || endDate.getTime() >= nowTimestamp)) {
      return `Last ${Math.min(5, assetRoute.length)} Locations:`;
    }

    return "";
  }, [isAllTimeSelected, endDate, assetRoute]);

  return (
    <>
      <div
        className={Style.container}
        data-cy="asset-route-container"
      >
        <div
          className={Style.date_range_button}
          onClick={() => {
            setShowDateRangePicker(!showDateRangePicker);
          }}
        >
          {`Time Range: ${timeRangeText}`}
          <CalendarIcon className={Style.icon} />
        </div>
        {showDateRangePicker && (
          <div className={Style.date_control}>
            <div
              className={Style.date_range_container}
              ref={popupRef}
            >
              <DateRangePicker
                initialStartDate={startDate}
                initialEndDate={endDate}
                onClose={() => {
                  return setShowDateRangePicker(false);
                }}
                onConfirm={(newIsAllTimeSelected, newStartDate, newEndDate) => {
                  setIsAllTimeSelected(newIsAllTimeSelected);
                  setShowDateRangePicker(false);

                  if (newIsAllTimeSelected) {
                    fetchAssetRoute();
                  } else {
                    fetchAssetRoute(newStartDate.getTime(), newEndDate.getTime());
                    setStartDate(newStartDate);
                    setEndDate(newEndDate);
                  }
                }}
                defaultDateRangeMs={defaultRouteTimeframeMs}
                allTimeSelected={isAllTimeSelected}
                quickFilterOptions={dateRangeQuickFilterOptions}
              />
            </div>
          </div>
        )}
        {isLoading ? (
          <Skeleton className={Style.loading_progress_graph} />
        ) : (
          <ProgressGraph
            stages={assetRoute.slice().reverse()}
            title={progressGraphTitle}
          />
        )}
        <PaginatedListTableWithTypes
          isLoading={isLoading}
          numItemsPerPage={5}
          headers={routeTableHeaders}
          dataList={assetRoute}
          paginatorLocation="top"
          headerRowContainerClassName={Style.header_row_container}
          headerContainerClassName={Style.table_header}
          itemContainerClassName={Style.table_item_container}
          itemRowContainerClassName={isLoading ? Style.loading_table_item_row : Style.table_item_row}
          emptyListContainerClassName={Style.empty_list_container}
        />
      </div>
      {fetchRouteFailed && (
        <div className={Style.status_popup_container}>
          <StatusPopupComponent
            showPopup
            message="Unable to retrieve asset route. Please contact an administrator for assistance."
            status={STATUS_OPTIONS.ERROR}
          />
        </div>
      )}
    </>
  );
};

AssetRouteFeature.defaultProps = {};

AssetRouteFeature.propTypes = {
  itemIdentifier: PropTypes.string.isRequired
};
