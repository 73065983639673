import React, { useEffect, useState } from "react";
import { TableSortLabel } from "@material-ui/core";

export const useSortableHeader = (defaultOrder, defaultOrderProperty) => {
  const [order, setOrder] = useState(defaultOrder || "asc");
  const [orderBy, setOrderBy] = useState(defaultOrderProperty || "");

  useEffect(() => {
    setOrder(defaultOrder);
    setOrderBy(defaultOrderProperty);
  }, [defaultOrder, defaultOrderProperty]);

  const handleRequestSort = (property) => {
    return () => {
      const isDesc = orderBy === property && order === "desc";
      setOrder(isDesc ? "asc" : "desc");
      setOrderBy(property);
    };
  };

  /**
   *
   * @param {RecordType[]} records
   * @param {'asc' | 'desc'} order
   * @param {string} orderBy
   * @returns {RecordType[]}
   */

  const getSortedData = (records, replacedOrderBy) => {
    const collator = new Intl.Collator("en", {
      numeric: true,
      sensitivity: "base"
    });
    const compareFn =
      order === "asc"
        ? (a, b) => {
            if (
              typeof a[replacedOrderBy || orderBy] === "number" ||
              typeof b[replacedOrderBy || orderBy] === "number"
            ) {
              return (a[replacedOrderBy || orderBy] || 0) - (b[replacedOrderBy || orderBy] || 0);
            }
            return collator.compare(b[replacedOrderBy || orderBy], a[replacedOrderBy || orderBy]);
          }
        : (a, b) => {
            if (
              typeof a[replacedOrderBy || orderBy] === "number" ||
              typeof b[replacedOrderBy || orderBy] === "number"
            ) {
              return (b[replacedOrderBy || orderBy] || 0) - (a[replacedOrderBy || orderBy] || 0);
            }
            return collator.compare(a[replacedOrderBy || orderBy], b[replacedOrderBy || orderBy]);
          };

    return [...records.sort(compareFn)];
  };

  const getSortableHeader = (headerInfo, elementProps) => {
    const headerContainerClass = elementProps?.headerContainerClass;
    // eslint-disable-next-line no-param-reassign
    delete elementProps?.headerContainerClass;
    return (
      <div
        key={headerInfo.id}
        style={{ flex: 1 }}
        className={headerContainerClass}
      >
        <TableSortLabel
          active={orderBy === headerInfo.id}
          direction={order}
          onClick={handleRequestSort(headerInfo.id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...elementProps}
        >
          {headerInfo.label}
        </TableSortLabel>
      </div>
    );
  };

  return {
    order,
    orderBy,
    getSortedData,
    getSortableHeader
  };
};
