import React, { useMemo, useCallback, useEffect } from "react";
import _ from "lodash";
import Style from "./LocationTable.module.css";
import PaginatedListTableWithTypes, {
  getValueByType
} from "../../../../../../../../components/paginated-list-table-with-types";
import useInventoryTrackPageStateContext from "../../../../../../contexts/inventory-track-page-state-context";
import useInventoryTrackPageDataSourceContext from "../../../../../../contexts/inventory-track-page-data-source-context";
import useDebounce from "../../../../../../../../hooks/use-debounce";
import useGetFilteredDataList from "../../hooks/use-get-filtered-data-list";
import useInventoryTrackPageConfigContext from "../../../../../../contexts/inventory-track-page-config-context";
import { TABLE_VIEW_TYPE_ID_MAP } from "../../../table-control-actions/features/table-view-type-control";
import { STATUS_COLOR_MAP } from "./data/constants";
import { exportCsv } from "../../../../../../../../common/Utilities";

export const LocationTable = () => {
  const {
    getURLByState,
    freeTextSearchInputString,
    applyLocationFilterFn,
    displayedTableHeadersMap,
    selectedViewModeId,
    applyItemTypeFilterFn,
    applyLotFilterFn,
    setExportCsvFn,
    selectedLocationId,
    selectedViewTypeId
  } = useInventoryTrackPageStateContext();
  const {
    childrenLocationsMetrics,
    itemTypesByLocationState,
    lotsByLocationState,
    locationTreeMap,
    isLocationTreeMapLoading
  } = useInventoryTrackPageDataSourceContext();

  const { listTableControl, tableViewTypeControl } = useInventoryTrackPageConfigContext();

  const csvFileName = useMemo(() => {
    return `${selectedLocationId === undefined ? "All locations" : locationTreeMap?.[selectedLocationId]?.name} - ${
      tableViewTypeControl?.[selectedViewTypeId].label
    }`;
  }, [locationTreeMap, selectedLocationId, selectedViewTypeId, tableViewTypeControl]);

  const headers = useMemo(() => {
    return displayedTableHeadersMap?.[TABLE_VIEW_TYPE_ID_MAP.location]?.[selectedViewModeId]
      ?.map((eachHeaderId) => {
        return listTableControl[TABLE_VIEW_TYPE_ID_MAP.location]?.headers.find(({ id }) => {
          return eachHeaderId === id;
        });
      })
      .filter((each) => {
        return !!each;
      });
  }, [listTableControl, displayedTableHeadersMap, selectedViewModeId]);

  const freeTextSearchInputTextDebounced = useDebounce(freeTextSearchInputString, 250);

  const filterCallbackFn = useCallback(
    (eachLocation) => {
      let shouldInclude = true;
      if (typeof applyItemTypeFilterFn === "function" && !eachLocation.totalItemTypeCount) {
        shouldInclude = false;
      }

      if (typeof applyLotFilterFn === "function" && !eachLocation.totalLotCount) {
        shouldInclude = false;
      }

      if (shouldInclude && typeof applyLocationFilterFn === "function") {
        shouldInclude = applyLocationFilterFn(eachLocation);
      }
      return shouldInclude;
    },
    [applyLocationFilterFn, applyItemTypeFilterFn, applyLotFilterFn]
  );

  const isLoading = useMemo(() => {
    return (
      itemTypesByLocationState.isLoading ||
      itemTypesByLocationState.hasNextPage ||
      lotsByLocationState.isLoading ||
      lotsByLocationState.hasNextPage
    );
  }, [
    itemTypesByLocationState.isLoading,
    itemTypesByLocationState.hasNextPage,
    lotsByLocationState.isLoading,
    lotsByLocationState.hasNextPage
  ]);

  const [processedLocationList, sortedIdReplacementMap] = useMemo(() => {
    const newSortedIdReplacementMap = {};
    return [
      childrenLocationsMetrics.map((eachLocation) => {
        const copiedEachLocation = _.cloneDeep(eachLocation);
        Object.keys(copiedEachLocation).forEach((eachAttribute) => {
          if (eachAttribute.toLowerCase().includes("status")) {
            newSortedIdReplacementMap[eachAttribute] = `${eachAttribute}Original`;
            copiedEachLocation[`${eachAttribute}Original`] = copiedEachLocation[eachAttribute];
            copiedEachLocation[eachAttribute] = [
              {
                title: copiedEachLocation[`${eachAttribute}Original`],
                color: STATUS_COLOR_MAP[copiedEachLocation[`${eachAttribute}Original`]]
              }
            ];
          }
        });
        return copiedEachLocation;
      }),
      newSortedIdReplacementMap
    ];
  }, [childrenLocationsMetrics]);

  const filteredLocationList = useGetFilteredDataList({
    freeTextSearchInputString: freeTextSearchInputTextDebounced,
    dataList: processedLocationList,
    filterCallbackFn,
    headers
  });

  useEffect(() => {
    const csvData = filteredLocationList.map((eachData) => {
      return {
        ...headers.reduce((accumulator, { id, label, type, timeFormat }) => {
          accumulator[label] = getValueByType(eachData[id], type, timeFormat, true);
          return accumulator;
        }, {})
      };
    });
    setExportCsvFn(() => {
      return () => {
        exportCsv(
          csvData,
          {
            header: headers.map((eachHeader) => {
              return eachHeader.label;
            }),
            cellDates: true
          },
          csvFileName
        );
      };
    });
  }, [filteredLocationList, headers, csvFileName]);

  return (
    <div className={Style.container}>
      <PaginatedListTableWithTypes
        isLoading={isLocationTreeMapLoading || isLoading}
        paginatorLocation="top"
        headers={headers}
        getTitleURLCallbackFn={(location) => {
          const param = { selectedLocationId: location.id };

          return getURLByState(param);
        }}
        dataList={filteredLocationList}
        sortIdReplacementMap={sortedIdReplacementMap}
      />
    </div>
  );
};
