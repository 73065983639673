import { UserProfile } from "../domains/user-profile";
import { UserConfiguration } from "../domains/user-configuration";

const CacheKeys = {
  userSelectedLocationObjectKey: "user-selected-location-object-key",
  personInformationWithLocationContextKey: "person-information-with-location-context-key",
  userConfigKey: "Xemelgo-user-configuration",
  userTestConfigKey: "Xemelgo-user-test-configuration",
  userProfileKey: "Xemelgo-user-profile",
  customerTestModeKey: "CustomerTestMode",
  workOrderTrackPageDataViewKey: "workOrderTrackPageDataView",
  sideNavigationNestedTabVerticalCollapsedKey: "Xemelgo-side-navigation-nested-tab-vertical-collapsed",
  sideNavigationHorizontalCollapsedKey: "Xemelgo-side-navigation-horizontal-collapsed",
  managementDashboardSiteLocationKey: "managementDashboardSiteLocation",
  assetMapSiteLocationKey: "assetMapSiteLocation",
  checkOutTableSelectedLocationKey: "checkOutSelectedLocation",
  printTemplateKey: "print-template",
  printerKey: "printer",
  inventoryTrackPageDataViewKey: "inventoryTrackPageDataView",
  inventoryTrackPageDisplayedHeadersKey: "inventoryTrackPageDisplayedHeadersMap"
};

const retrieveCachedObject = (keyName, newDomainFn) => {
  const content = localStorage.getItem(keyName);

  if (content) {
    return newDomainFn(content);
  }

  return null;
};

export const LocalCacheService = {
  // ---------------------- USER LOCATION ---------------------- //

  setUserSelectedLocationObject(locationObject) {
    localStorage.setItem(CacheKeys.userSelectedLocationObjectKey, JSON.stringify(locationObject));
  },

  getUserSelectedLocationObject() {
    return retrieveCachedObject(CacheKeys.userSelectedLocationObjectKey, (objectJSON) => {
      return JSON.parse(objectJSON) || {};
    });
  },

  setUserInfoWithLocationContext(userInfo) {
    localStorage.setItem(CacheKeys.personInformationWithLocationContextKey, JSON.stringify(userInfo));
  },

  getUserInfoWithLocationContext() {
    return retrieveCachedObject(CacheKeys.personInformationWithLocationContextKey, (objectJSON) => {
      return JSON.parse(objectJSON);
    });
  },

  // ---------------------- USER  ---------------------- //

  saveUserProfile(userProfile) {
    localStorage.setItem(CacheKeys.userProfileKey, userProfile.toJson());
  },

  loadUserProfile() {
    return retrieveCachedObject(CacheKeys.userProfileKey, (content) => {
      return new UserProfile(content);
    });
  },

  clearUserProfile() {
    localStorage.removeItem(CacheKeys.userProfileKey);
  },

  saveUserConfig(userConfiguration) {
    localStorage.setItem(CacheKeys.userConfigKey, userConfiguration.toJson());
  },

  saveUserTestConfig(userConfiguration) {
    localStorage.setItem(CacheKeys.userTestConfigKey, userConfiguration.toJson());
  },

  clearUserTestConfig() {
    localStorage.removeItem(CacheKeys.userTestConfigKey);
  },

  loadUserConfig() {
    let configKey;
    const currentMode = sessionStorage.getItem(CacheKeys.customerTestModeKey);
    if (currentMode === "Test") {
      configKey = CacheKeys.userTestConfigKey;
    } else {
      configKey = CacheKeys.userConfigKey;
    }
    return retrieveCachedObject(configKey, (config) => {
      return UserConfiguration.parse(config);
    });
  },

  clearUserConfig() {
    localStorage.removeItem(CacheKeys.userConfigKey);
  },

  clearAllCaches() {
    localStorage.clear();
  },

  saveWorkOrderTrackPageDataView(dataViewKey) {
    localStorage.setItem(CacheKeys.workOrderTrackPageDataViewKey, dataViewKey);
  },

  getWorkOrderTrackPageDataView() {
    return retrieveCachedObject(CacheKeys.workOrderTrackPageDataViewKey, (content) => {
      return content;
    });
  },

  saveSideNavigationNestedTabVerticalCollapsed(isCollapsed) {
    localStorage.setItem(CacheKeys.sideNavigationNestedTabVerticalCollapsedKey, isCollapsed);
  },

  getSideNavigationNestedTabVerticalCollapsed(tabId) {
    return retrieveCachedObject(CacheKeys.sideNavigationNestedTabVerticalCollapsedKey, (collapsedState) => {
      const cache = JSON.parse(collapsedState);
      return cache && cache[tabId] ? cache[tabId] : false;
    });
  },

  saveSideNavigationHorizontalCollapsed(isCollapsed) {
    localStorage.setItem(CacheKeys.sideNavigationHorizontalCollapsedKey, isCollapsed);
  },

  getSideNavigationHorizontalCollapsed() {
    return retrieveCachedObject(CacheKeys.sideNavigationHorizontalCollapsedKey, (isCollapsed) => {
      return isCollapsed === "true";
    });
  },

  saveManagementDashboardSiteLocation(locationId, viewState) {
    localStorage.setItem(CacheKeys.managementDashboardSiteLocationKey, JSON.stringify({ locationId, viewState }));
  },

  getManagementDashboardSiteLocation() {
    return retrieveCachedObject(CacheKeys.managementDashboardSiteLocationKey, (locationData) => {
      return JSON.parse(locationData || {}) || {};
    });
  },

  saveAssetMapSiteLocation(locationId, viewState) {
    localStorage.setItem(CacheKeys.assetMapSiteLocationKey, JSON.stringify({ locationId, viewState }));
  },

  getAssetMapSiteLocation() {
    return retrieveCachedObject(CacheKeys.assetMapSiteLocationKey, (locationData) => {
      return JSON.parse(locationData || {}) || {};
    });
  },

  saveCheckOutTableSelectedLocation(selectedLocation) {
    localStorage.setItem(CacheKeys.checkOutTableSelectedLocationKey, JSON.stringify(selectedLocation));
  },

  getCheckOutTableSelectedLocation() {
    return retrieveCachedObject(CacheKeys.checkOutTableSelectedLocationKey, (selectedLocation) => {
      return selectedLocation ? JSON.parse(selectedLocation) : {};
    });
  },
  savePrintOptions(solutionType, selectedTemplate, selectedPrinter) {
    if (selectedTemplate) {
      const cachedSolutionToTemplateMap =
        retrieveCachedObject(CacheKeys.printTemplateKey, (savedPrintTemplate) => {
          return JSON.parse(savedPrintTemplate || "{}");
        }) || {};
      cachedSolutionToTemplateMap[solutionType] = selectedTemplate;
      localStorage.setItem(CacheKeys.printTemplateKey, JSON.stringify(cachedSolutionToTemplateMap));
    }
    if (selectedPrinter) {
      const cachedSolutionToPrinterMap =
        retrieveCachedObject(CacheKeys.printerKey, (savedPrinter) => {
          return JSON.parse(savedPrinter || "{}");
        }) || {};
      cachedSolutionToPrinterMap[solutionType] = selectedPrinter;
      localStorage.setItem(CacheKeys.printerKey, JSON.stringify(cachedSolutionToPrinterMap));
    }
  },

  getPrintOptions(solutionType) {
    const cachedTemplate = retrieveCachedObject(CacheKeys.printTemplateKey, (savedPrintTemplate) => {
      const printTemplates = JSON.parse(savedPrintTemplate);
      return printTemplates[solutionType] || {};
    });

    const cachedPrinter = retrieveCachedObject(CacheKeys.printerKey, (savedPrinter) => {
      const printers = JSON.parse(savedPrinter);
      return printers[solutionType] || {};
    });

    return { cachedTemplate, cachedPrinter };
  },

  // Inventory Track Page V3

  saveInventoryTrackPageDataView(dataViewKey) {
    localStorage.setItem(CacheKeys.inventoryTrackPageDataViewKey, dataViewKey);
  },

  getInventoryTrackPageDataView() {
    return retrieveCachedObject(CacheKeys.inventoryTrackPageDataViewKey, (content) => {
      return content;
    });
  },

  saveInventoryTrackPageDisplayedHeadersMap(displayHeadersMap) {
    localStorage.setItem(CacheKeys.inventoryTrackPageDisplayedHeadersKey, JSON.stringify(displayHeadersMap || {}));
  },

  getInventoryTrackPageDisplayedHeadersMap() {
    return (
      retrieveCachedObject(CacheKeys.inventoryTrackPageDisplayedHeadersKey, (content) => {
        return JSON.parse(content || "{}") || {};
      }) || {}
    );
  }
};
