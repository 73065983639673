import React from "react";
import PropTypes from "prop-types";
import { CheckCircleOutline, ErrorOutline, HourglassEmptyTwoTone } from "@material-ui/icons";
import Style from "./StatusPopupComponent.module.css";
import { STATUS_OPTIONS } from "./data/constants";

const getIcon = (status) => {
  switch (status) {
    case STATUS_OPTIONS.ERROR:
      return <ErrorOutline />;
    case STATUS_OPTIONS.LOADING:
      return <HourglassEmptyTwoTone />;
    case STATUS_OPTIONS.SUCCESS:
    default:
      return <CheckCircleOutline />;
  }
};

export const StatusPopupComponent = ({ showPopup, message, status }) => {
  if (!showPopup) {
    return null;
  }

  return (
    <div className={`${Style.status_modal} ${status === STATUS_OPTIONS.ERROR ? `${Style.error}` : ""}`}>
      {getIcon(status)}
      <p className={Style.message_text}>{message}</p>
    </div>
  );
};

StatusPopupComponent.defaultProps = {
  showPopup: false,
  status: STATUS_OPTIONS.SUCCESS
};

StatusPopupComponent.propTypes = {
  showPopup: PropTypes.bool,
  message: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(STATUS_OPTIONS))
};
