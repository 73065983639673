/* eslint-disable radix */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import AutoSizeTextArea from "../../../../../../components/AutoSizeTextArea/AutoSizeTextArea";
import GeneralizedModal from "../../../../../../components/modal/GeneralizedModal";
import Style from "./LocationThresholdSettingModal.module.css";
import useWorkOrderTrackPageStateContext from "../../../../contexts/work-order-track-page-state-context";
import useWorkOrderTrackPageDataSourceContext from "../../../../contexts/work-order-track-page-data-source-context";
import { useXemelgoClient } from "../../../../../../services/xemelgo-service";

export const LocationThresholdSettingModal = ({ show, onClose, optionControl }) => {
  const [warningThreshold, setWarningThreshold] = useState("");
  const [criticalThreshold, setCriticalThreshold] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { selectedLocationId, fetchLocationTreeFn } = useWorkOrderTrackPageStateContext();
  const { locationTreeMap } = useWorkOrderTrackPageDataSourceContext();
  const xemelgoClient = useXemelgoClient();

  const { percentageThresholdKeyMap = {} } = optionControl;

  useEffect(() => {
    if (Object.keys(optionControl || {}).length && Object.keys(locationTreeMap || {}).length && show) {
      setIsLoading(true);
      const {
        [percentageThresholdKeyMap.warning]: newWarningThreshold,
        [percentageThresholdKeyMap.critical]: newCriticalThreshold
      } = locationTreeMap[selectedLocationId] || {};

      setCriticalThreshold(newCriticalThreshold?.toString() || "");
      setWarningThreshold(newWarningThreshold?.toString() || "");
      setIsLoading(false);
    }
  }, [locationTreeMap, selectedLocationId, optionControl, show]);

  const locationName = useMemo(() => {
    return `${locationTreeMap[selectedLocationId]?.name} ${locationTreeMap[selectedLocationId]?.category}`;
  }, [selectedLocationId, locationTreeMap]);

  const updateLocationThreshold = async () => {
    const locationClient = xemelgoClient.getLocationClient();
    await locationClient.updateLocationV2(selectedLocationId, {
      [percentageThresholdKeyMap.critical]: criticalThreshold ? parseInt(criticalThreshold) : null,
      [percentageThresholdKeyMap.warning]: warningThreshold ? parseInt(warningThreshold) : null
    });
    await fetchLocationTreeFn();
  };

  const resetInputValue = () => {
    setCriticalThreshold("");
    setWarningThreshold("");
  };

  return (
    <GeneralizedModal
      showModal={show}
      centered
      title="Location Status Threshold"
      modalBodyComponent={
        <div className={Style.container}>
          <div className={Style.description_container}>
            <p>Location Name: </p>
            <p className={Style.font_bold}>{locationName}</p>
          </div>

          <div className={Style.input_group}>
            <p className={Style.label_text}>Critical Threshold:</p>
            <div className={Style.input_container}>
              <AutoSizeTextArea
                textareaClassName={Style.input}
                value={criticalThreshold}
                numberOnly
                onChangeText={setCriticalThreshold}
              />
            </div>
            <p>% of orders are critical</p>
          </div>
          <div className={Style.input_group}>
            <p className={Style.label_text}>Warning Threshold:</p>
            <div className={Style.input_container}>
              <AutoSizeTextArea
                textareaClassName={Style.input}
                value={warningThreshold}
                numberOnly
                onChangeText={setWarningThreshold}
              />
            </div>
            <p>% of orders are warning</p>
          </div>
        </div>
      }
      modalFooterButtons={[
        {
          id: "cancel",
          title: "Cancel",
          onClick: () => {
            resetInputValue();
            onClose();
          }
        },
        {
          id: "save",
          title: "Save",
          className: Style.save_button,
          onClick: async () => {
            setIsLoading(true);
            await updateLocationThreshold();
            setIsLoading(false);
            resetInputValue();
            onClose();
          },
          disabled: isLoading
        }
      ]}
    />
  );
};

LocationThresholdSettingModal.defaultProps = {
  show: false,
  onClose: () => {},
  optionControl: {}
};

LocationThresholdSettingModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  optionControl: PropTypes.objectOf(PropTypes.any)
};
