import React, { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { TAB_OPTION_MAP } from "../../data/constants";
import useAssetTrackPageConfigContext from "../asset-track-page-config-context";
import useAssetTrackPageDataSourceContext from "../asset-track-page-data-source-context";
import { useXemelgoAppsyncClient } from "../../../../services/xemelgo-appsync-service";
import useMixpanelContext from "../../../../context/mixpanel-context";
import {
  ASSET_TRACK_PAGE_V2,
  ASSET_TRACK_PAGE_V2_STEPS
} from "../../../../constants/mixpanel-constant/assetTrackPageV2";

const AssetTrackPageStateContext = React.createContext();

const initialState = {
  selectedLocationId: undefined,
  selectedTab: TAB_OPTION_MAP.assetTab,
  searchInput: "",
  areLocationsLoading: true,
  locationMetricsLoading: true,
  assetsLoading: false,
  showCreateModal: false,
  applySideFilterFn: () => {},
  selectedAssetIdMap: {},
  enabledMultiSelectOptions: [],
  expandedLocations: {},
  selectedColumns: {},
  sideFilterValue: {},
  locationHierarchy: {},
  displayedData: [],
  locationSortOrder: "asc",
  locationsToDisplay: []
};

export const useAssetTrackPageStateContext = () => {
  return useContext(AssetTrackPageStateContext);
};

export const AssetTrackPageStateContextProvider = ({ children }) => {
  const client = useXemelgoClient();
  const appsyncClient = useXemelgoAppsyncClient();

  const { sendMixPanelEvent } = useMixpanelContext();
  const { listTableOptions, locationCategories, multiSelectOptions, unknownLocationControl, locationMetricsFields } =
    useAssetTrackPageConfigContext();
  const {
    isAdmin,
    setLocationTreeMap,
    assetMap,
    setAssetMap,
    locationsToAssetsMap,
    setLocationsToAssetMap,
    setLocationToMetricFieldsMap,
    locationToTypeMetricsMap,
    setLocationToTypeMetricsMap,
    locationTreeMap,
    flatCategoryTrees,
    setFlatCategoryTrees
  } = useAssetTrackPageDataSourceContext();

  const [areLocationsLoading, setAreLocationsLoading] = useState(initialState.areLocationsLoading);
  const [assetsLoading, setAssetsLoading] = useState(initialState.assetsLoading);
  const [showCreateModal, setShowCreateModal] = useState(initialState.showCreateModal);
  const [locationMetricsLoading, setLocationMetricsLoading] = useState(initialState.locationMetricsLoading);
  const [expandedLocations, setExpandedLocations] = useState(initialState.expandedLocations);
  const [searchInput, setSearchInput] = useState(initialState.searchInput);
  const [locationSortOrder, setLocationSortOrder] = useState(initialState.locationSortOrder);
  const [selectedAssetIdMap, setSelectedAssetIdMap] = useState(initialState.selectedAssetIdMap);
  const [selectedLocationId, setSelectedLocationIdState] = useState(initialState.selectedLocationId);
  const [selectedTab, setSelectedTabState] = useState(initialState.selectedTab);
  const [displayedData, setDisplayedData] = useState(initialState.displayedData);
  const [selectedColumns, setSelectedColumns] = useState(initialState.selectedColumns);
  const [locationHierarchy, setLocationHierarchy] = useState(initialState.locationHierarchy);
  const [sideFilterValue, setSideFilterValue] = useState(initialState.sideFilterValue);
  const [applySideFilterFn, setApplySideFilterFn] = useState(initialState.applySideFilterFn);
  const [locationsToDisplay, setLocationsToDisplay] = useState(initialState.locationsToDisplay);

  const { search, pathname } = useLocation();
  const history = useHistory();

  const setSelectedLocationId = (newSelectedLocationId) => {
    const newParsedString = { ...queryString.parse(search), locationId: newSelectedLocationId };
    const newSearchString = queryString.stringify(newParsedString);
    setSelectedLocationIdState(newSelectedLocationId);
    history.push(`${pathname}${newSearchString ? `?${newSearchString}` : ""}`);
  };

  const setSelectedTab = (newSelectedTab) => {
    let newTab = newSelectedTab;
    if (newSelectedTab !== TAB_OPTION_MAP.assetTab && newSelectedTab !== TAB_OPTION_MAP.assetTypeTab) {
      newTab = TAB_OPTION_MAP.assetTab;
    }
    const newParsedString = { ...queryString.parse(search), tab: newTab };
    const newSearchString = queryString.stringify(newParsedString);
    setSelectedTabState(newTab);
    history.replace(`${pathname}${newSearchString ? `?${newSearchString}` : ""}`);
  };

  useEffect(() => {
    const newSelectedColumns = {};

    Object.values(TAB_OPTION_MAP).forEach((tab) => {
      const defaultColumns = listTableOptions[tab].headers.filter((header) => {
        return header.defaultColumn;
      });

      newSelectedColumns[tab] = defaultColumns;
    });

    setSelectedColumns(newSelectedColumns);
  }, [listTableOptions]);

  useEffect(() => {
    if (search) {
      const { locationId, tab } = queryString.parse(search);

      if (tab !== selectedTab) {
        setSelectedTab(tab || initialState.selectedTab);
      }

      if (locationId !== selectedLocationId) {
        setSelectedLocationIdState(locationId || initialState.selectedLocationId);
      }
    }
  }, [search]);

  useEffect(() => {
    setSearchInput(initialState.searchInput);
  }, [selectedTab, selectedLocationId]);

  useEffect(() => {
    if (flatCategoryTrees) {
      updateTypeCategoryInformation();
    }
  }, [flatCategoryTrees]);

  const enabledMultiSelectOptions = useMemo(() => {
    return multiSelectOptions.filter((option) => {
      return !option.adminOnly || isAdmin;
    });
  }, [multiSelectOptions, isAdmin]);

  const getSubTree = (locationId, locationTree) => {
    const location = locationTree[locationId];
    const subTree = {};
    subTree[locationId] = {};
    for (const childLocationId of location.childLocations) {
      if (childLocationId !== locationId && locationTree[childLocationId].directParentId === locationId) {
        subTree[childLocationId] = getSubTree(childLocationId, locationTree);
      }
    }
    return subTree;
  };

  const fetchLocationTree = async () => {
    setAreLocationsLoading(true);
    const locationAttributes = ["id", "name", "identifier"];

    const locationClient = await client.getLocationClient();
    const newLocationTree = await locationClient.getLocationTree(
      locationAttributes,
      !locationCategories?.length || locationCategories.includes("all") ? null : locationCategories
    );

    if (unknownLocationControl?.enabled && unknownLocationControl?.properties?.id) {
      newLocationTree[unknownLocationControl?.properties?.id] = {
        childLocations: [unknownLocationControl?.properties?.id],
        ...unknownLocationControl?.properties
      };
    }

    const newLocationHierarchy = {};
    Object.keys(newLocationTree).forEach((locationId) => {
      const location = newLocationTree[locationId];
      if (!location.directParentId) {
        newLocationHierarchy[locationId] = getSubTree(locationId, newLocationTree);
      }
    });

    const newExpandedLocations = {};
    Object.keys(newLocationTree).forEach((locationId) => {
      newExpandedLocations[locationId] = false;
    });

    setLocationHierarchy(newLocationHierarchy);
    setLocationTreeMap(newLocationTree);
    setExpandedLocations(newExpandedLocations);
    setAreLocationsLoading(false);

    return newLocationTree;
  };

  const fetchLocationMetrics = async (locationTree) => {
    setLocationMetricsLoading(true);
    const assetAppsyncClient = await appsyncClient.getAssetClient();

    const locationIds = Object.keys(locationTree);

    const itemTypeMetricsPromise = assetAppsyncClient.getMetricsByLocationIds("itemType", locationIds);
    const locationMetricsMap = await assetAppsyncClient.getMetricsByLocationIds("items", locationIds);

    const { aggregateRootMetrics, newLocationMetricsFields } = locationIds.reduce(
      (accumulator, locationId) => {
        const metrics = [];
        locationMetricsFields.forEach((metric) => {
          if (locationMetricsMap[locationId] && locationMetricsMap[locationId].counts[metric.id] !== undefined) {
            const metricValue = locationMetricsMap[locationId].counts[metric.id];
            metrics.push({
              ...metric,
              value: metricValue
            });

            if (!locationTree[locationId].directParentId) {
              accumulator.aggregateRootMetrics[metric.id] =
                (accumulator.aggregateRootMetrics[metric.id] || 0) + metricValue;
            }
          }
        });
        accumulator.newLocationMetricsFields[locationId] = metrics;
        return accumulator;
      },
      { aggregateRootMetrics: {}, newLocationMetricsFields: {} }
    );

    const rootMetrics = locationMetricsFields.map((metric) => {
      return {
        ...metric,
        value: aggregateRootMetrics[metric.id]
      };
    });
    newLocationMetricsFields.root = rootMetrics;

    setLocationToMetricFieldsMap(newLocationMetricsFields);
    setLocationToTypeMetricsMap(await itemTypeMetricsPromise);
    setLocationMetricsLoading(false);
  };

  const updateTypeCategoryInformation = () => {
    if (Object.keys(locationToTypeMetricsMap).length) {
      const newTypeMap = { ...locationToTypeMetricsMap };

      Object.keys(newTypeMap).forEach((locationId) => {
        Object.keys(newTypeMap[locationId]).forEach((typeId) => {
          const type = { ...locationToTypeMetricsMap[locationId][typeId] };
          const { categoryIds = [] } = type.properties;

          categoryIds.forEach((categoryId) => {
            const category = flatCategoryTrees[categoryId] || {};
            if (category.depth === 0) {
              newTypeMap[locationId][typeId].properties.itemTypeCategory = category.identifier;
            }
            if (category.depth === 1) {
              newTypeMap[locationId][typeId].properties.itemTypeSubcategory = category.identifier;
            }
          });
        });
      });

      setLocationToTypeMetricsMap(newTypeMap);
    }
  };

  const fetchCategories = async () => {
    const categoryClient = await client.getCategoryClient();
    setFlatCategoryTrees(await categoryClient.getFlatCategoryTrees());
  };

  useEffect(() => {
    const isLoading = assetsLoading || !flatCategoryTrees;

    if (isLoading || (selectedLocationId && !locationTreeMap[selectedLocationId])) {
      return;
    }

    // Only query for leaf locations that have not been queried yet
    let locationIdsToQuery = selectedLocationId
      ? locationTreeMap[selectedLocationId].childLocations
      : Object.keys(locationTreeMap);
    locationIdsToQuery = locationIdsToQuery.filter((locationId) => {
      return !locationsToAssetsMap[locationId];
    });

    locationIdsToQuery = locationIdsToQuery.slice(0, 100);

    if (locationIdsToQuery.length > 0) {
      fetchAssets(locationIdsToQuery);
    }
  }, [selectedLocationId, locationsToAssetsMap, locationTreeMap, assetsLoading, flatCategoryTrees]);

  const fetchAssets = useCallback(
    async (locationIdsToQuery) => {
      setAssetsLoading(true);
      const assetAppsyncClient = await appsyncClient.getAssetClient();

      const newLocationsToAssetsMap = { ...locationsToAssetsMap };
      const newAssetMap = { ...assetMap };

      const selection = [
        "properties",
        "timestamp",
        "result",
        "param",
        "groupKey",
        "properties/id",
        "properties/itemTypeCategoryIds"
      ];
      selection.push(
        ...listTableOptions[TAB_OPTION_MAP.assetTab].headers.map((header) => {
          return `properties/${header.id}`;
        })
      );

      await Promise.all(
        locationIdsToQuery.map(async (locationId) => {
          let results = await assetAppsyncClient.getItemMetricsByLocationIds("asset", [locationId], selection);

          if (Object.keys(flatCategoryTrees).length) {
            results = results.map((asset) => {
              const newAsset = { ...asset };
              const categoryIds = asset.itemTypeCategoryIds;

              categoryIds.forEach((categoryId) => {
                const category = flatCategoryTrees[categoryId] || {};
                if (category.depth === 0) {
                  newAsset.itemTypeCategory = category.identifier;
                }
                if (category.depth === 1) {
                  newAsset.itemTypeSubcategory = category.identifier;
                }
              });

              return newAsset;
            });
          }

          newLocationsToAssetsMap[locationId] = results;
          setLocationsToAssetMap(newLocationsToAssetsMap);

          results.forEach((asset) => {
            newAssetMap[asset.id] = asset;
          });
        })
      );

      sendMixPanelEvent(ASSET_TRACK_PAGE_V2, ASSET_TRACK_PAGE_V2_STEPS.SIDE_FILTER_APPLIED);
      setAssetMap(newAssetMap);
      setAssetsLoading(false);
    },
    [locationsToAssetsMap, assetMap, listTableOptions, flatCategoryTrees]
  );

  return (
    <AssetTrackPageStateContext.Provider
      value={{
        areLocationsLoading,
        assetsLoading,
        locationMetricsLoading,
        fetchLocationTree,
        fetchLocationMetrics,
        fetchCategories,
        enabledMultiSelectOptions,
        locationHierarchy,
        selectedLocationId,
        setSelectedLocationId,
        selectedTab,
        setSelectedTab,
        searchInput,
        setSearchInput,
        locationSortOrder,
        setLocationSortOrder,
        expandedLocations,
        setExpandedLocations,
        selectedAssetIdMap,
        setSelectedAssetIdMap,
        showCreateModal,
        setShowCreateModal,
        displayedData,
        setDisplayedData,
        selectedColumns,
        setSelectedColumns,
        applySideFilterFn,
        setApplySideFilterFn,
        sideFilterValue,
        setSideFilterValue,
        locationsToDisplay,
        setLocationsToDisplay
      }}
    >
      {children}
    </AssetTrackPageStateContext.Provider>
  );
};
