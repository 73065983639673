import React from "react";
import PropTypes from "prop-types";
import Style from "./FilterBar.module.css";

export const FilterBar = ({ filterInput, onFilter }) => {
  return (
    <div className={Style.filter_bar_container}>
      <span className={`fa fa-search ${Style.filter_bar_icon}`} />
      <input
        onChange={({ currentTarget }) => {
          onFilter(currentTarget.value);
        }}
        value={filterInput}
        className={Style.filter_bar}
        placeholder="Type to Filter"
      />
    </div>
  );
};

FilterBar.propTypes = {
  filterInput: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired
};
