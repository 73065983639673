import React, { useEffect, useMemo } from "react";
import Style from "../../../../CheckOutTable.module.css";
import MultiInputForm from "../../../../../../components/multi-input-form";
import useCheckOutTableConfigContext from "../../../../contexts/check-out-table-config-context";
import useCheckOutTableStateContext from "../../../../contexts/check-out-table-state-context";

export const SidePanel = () => {
  const { panelValues, setPanelValues, optionTemplateMap } = useCheckOutTableStateContext();
  const { sidePanelAttributes } = useCheckOutTableConfigContext();

  const formattedPanelAttributes = useMemo(() => {
    return sidePanelAttributes.map((attribute) => {
      const { optionTemplate } = attribute;
      const options = optionTemplateMap[optionTemplate] || attribute.options || [];
      return {
        ...attribute,
        value: panelValues[attribute.id],
        options
      };
    });
  }, [sidePanelAttributes, panelValues, optionTemplateMap]);

  const onChange = (id, value) => {
    const newPanelValues = { ...panelValues };
    newPanelValues[id] = value;
    setPanelValues(newPanelValues);
  };

  return (
    <div className={Style.side_panel_container}>
      <MultiInputForm
        formFields={formattedPanelAttributes}
        containerClassName={Style.multi_input_container}
        fieldContainerClassName={Style.side_panel_field_container}
        onChange={onChange}
        isLoading={false}
      />
    </div>
  );
};
