import React, { useMemo, useState, useEffect, useRef } from "react";
import { getStackedXemelgoLogo } from "../../../../../../common/Utilities";
import Style from "../../../../CheckOutTable.module.css";
import ListTable from "../../../../../../components/ListTable/ListTable";
import {
  TABLE_COLUMN_SIZE_MAP,
  ROW_COUNT_ATTRIBUTE,
  ROW_COUNT_HEADER_LABEL,
  CLEAR_BUTTON_SECTION,
  CLEAR_BUTTON_ID
} from "../../../../data/constants";
import useCheckOutTableConfigContext from "../../../../contexts/check-out-table-config-context";
import useCheckOutTableStateContext from "../../../../contexts/check-out-table-state-context";

export const ItemTable = () => {
  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState(0);

  const { tableSections, rowBackgroundColor, tableTextStyle } = useCheckOutTableConfigContext();
  const { filterInput, itemTypeReports, setItemTypeReports, itemByTag, setItemByTag } = useCheckOutTableStateContext();

  const headers = useMemo(() => {
    return [...tableSections, CLEAR_BUTTON_SECTION];
  }, [tableSections]);

  const filterFunction = (input, each) => {
    const lowerCaseInput = input.toLowerCase();
    let match = false;
    let keysToCheckList = [];

    keysToCheckList = tableSections.reduce((accumulator, section) => {
      const { attributes = [] } = section;
      const currentAttributes = [];
      attributes.forEach((attribute) => {
        if (attribute.type !== "image") {
          currentAttributes.push(attribute.id);
        }
      });
      return [...accumulator, ...currentAttributes];
    }, []);

    keysToCheckList.forEach((eachKey) => {
      let eachValue = each[eachKey];
      if (typeof eachValue !== "string") {
        eachValue = String(eachValue);
      }
      if (eachValue && eachValue.toLowerCase().includes(lowerCaseInput)) {
        match = true;
      }
    });

    return match;
  };

  const filteredReports = useMemo(() => {
    if (itemTypeReports && filterFunction) {
      return itemTypeReports.filter((row) => {
        return filterFunction(filterInput, row);
      });
    }
    return [];
  }, [itemTypeReports, filterInput]);

  useEffect(() => {
    const newLatestTimestamp = filteredReports[filteredReports.length - 1]?.lastUpdatedTimestamp;
    if (listTableRef.current && newLatestTimestamp > lastUpdatedTimestamp) {
      setLastUpdatedTimestamp(newLatestTimestamp);
      listTableRef.current.scrollToItem(filteredReports.length - 1);
    }
  }, [filteredReports, lastUpdatedTimestamp]);

  const renderHeader = (header) => {
    const { size } = header;
    const flexSize = TABLE_COLUMN_SIZE_MAP[size] || TABLE_COLUMN_SIZE_MAP.default;
    return (
      <li
        key={header?.label}
        style={{
          flex: header?.label === ROW_COUNT_HEADER_LABEL ? 1 : flexSize
        }}
      >
        {header?.label || ""}
      </li>
    );
  };

  const renderRow = (row, index) => {
    return (
      <div
        key={row.id}
        className={Style.row_container}
        style={{
          backgroundColor: rowBackgroundColor
        }}
      >
        {renderSections(row, index)}
      </div>
    );
  };

  const onClear = (id) => {
    const newItemByTag = { ...itemByTag };
    const newItemTypeReports = [...itemTypeReports];
    const reportIndex = newItemTypeReports.findIndex((report) => {
      return report.id === id;
    });
    const [report] = newItemTypeReports.splice(reportIndex, 1);
    report.epcs.forEach((epc) => {
      delete newItemByTag[epc];
    });
    setItemByTag(newItemByTag);
    setItemTypeReports(newItemTypeReports);
  };

  const renderSections = (row, index) => {
    return headers.map((section) => {
      const { attributes = [], size } = section;
      const flexSize = TABLE_COLUMN_SIZE_MAP[size] || TABLE_COLUMN_SIZE_MAP.default;
      return (
        <div
          style={{
            ...tableTextStyle,
            backgroundColor: rowBackgroundColor,
            flex: flexSize
          }}
          className={Style.section_container}
          key={`${row.id}-${section.label}`}
        >
          <div className={Style.section_content_container}>
            {attributes.map((attribute) => {
              const { id, label, type } = attribute;
              let value = row[id];
              if (id === ROW_COUNT_ATTRIBUTE) {
                return (
                  <div
                    style={{
                      ...tableTextStyle,
                      backgroundColor: rowBackgroundColor,
                      flex: 1
                    }}
                  >
                    {index + 1}
                  </div>
                );
              }

              if (id === CLEAR_BUTTON_ID) {
                return (
                  <button
                    onClick={() => {
                      onClear(row.id);
                    }}
                    className={Style.clear_button}
                  >
                    Clear
                  </button>
                );
              }

              if (label === "Color" && row.color_desc_ts) {
                value = `${row[id]}-${row.color_desc_ts}`;
              }
              if (id === "qty") {
                value = `${row.epcs.length}`;
              }
              if (type == "image") {
                return (
                  <img
                    key={`${row.id}-${row[id]}`}
                    src={row[id] || getStackedXemelgoLogo("dark")}
                    height="100px"
                    width="100px"
                    className={Style.row_image}
                  />
                );
              }
              return (
                <div
                  style={{ padding: !label && !value ? "1em 0" : 0, display: !label && !value ? "none" : "" }}
                  className={Style.section_text_container}
                  key={`${row.id}-${row[id]}`}
                >
                  {label && <p className={Style.section_label}>{`${label}:`}</p>}
                  <p className={Style.section_value}>{value}</p>
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  const listTableRef = useRef(null);

  return (
    <div className={Style.table}>
      <ListTable
        header={headers}
        headerContainerClassName={Style.header}
        data={filteredReports}
        renderHeader={renderHeader}
        renderItem={renderRow}
        renderEmptyList={() => {
          return <div className={Style.empty_items_container}>No Matching Results</div>;
        }}
        ref={listTableRef}
      />
    </div>
  );
};
