import React, { useEffect, useState } from "react";
import { GetApp as ExportIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import Style from "./PackageTrackPageFeature.module.css";
import ScreenFrame from "../../components/ScreenFrame/ScreenFrame";
import { ReactComponent as PackageIcon } from "../../assets/icons/asset.svg";
import xemelgoStyle from "../../styles/variable";
import { PackageTrackPageConfigContextProvider } from "./contexts/package-track-page-config-context";
import { PackageTrackPageDataSourceContextProvider } from "./contexts/package-track-page-data-source-context";
import usePackageTrackPageStateContext, {
  PackageTrackPageStateContextProvider
} from "./contexts/package-track-page-state-context";
import FreeTextSearchInput from "../../components/free-text-search-input";
import Breadcrumb from "./features/breadcrumb";
import SideFilters from "./features/side-filters";
import LocationTree from "./features/location-tree";
import useURLControlHook from "./hooks/use-url-control-hook";
import LoadingCircle from "../../components/loading/LoadingCircle";
import usePackageTrackPageInitializationHook from "./hooks/use-package-track-page-initialization-hook";
import usePackageTrackPageExportCSVHook from "./hooks/use-package-track-page-export-csv-hook";

export const PackageTrackPageFeature = ({ initializeHook = () => {} }) => {
  const initializeFn = initializeHook();
  const { freeTextSearchInputText, setFreeTextSearchInputText, exportCsvFn } = usePackageTrackPageStateContext();

  const [isLoading, setIsLoading] = useState(true);
  useURLControlHook();
  usePackageTrackPageExportCSVHook();
  useEffect(() => {
    const onLoad = async () => {
      setIsLoading(true);
      await initializeFn();
      setIsLoading(false);
    };
    onLoad();
  }, []);

  return (
    <ScreenFrame
      title="Package"
      color={xemelgoStyle.theme.INVENTORY_PRIMARY}
      secondaryColor={xemelgoStyle.theme.INVENTORY_SECONDARY}
      titleIconComponent={
        <PackageIcon
          color={xemelgoStyle.theme.INVENTORY_PRIMARY}
          width={25}
          height={25}
        />
      }
    >
      <div className={`${Style.flex_row} ${Style.container}`}>
        <div className={`${Style.side_column}`}>
          <div className={Style.side_filter_container}>
            <SideFilters />
          </div>
        </div>
        <div className={`${Style.flex_column} ${Style.content}`}>
          <Breadcrumb />
          <div className={`${Style.flex_row} ${Style.filter_bar_group}`}>
            <div className={Style.free_text_search_container}>
              <FreeTextSearchInput
                value={freeTextSearchInputText}
                onChangeText={setFreeTextSearchInputText}
              />
            </div>
            <button
              type="button"
              className={`${Style.flex_row} ${Style.export_button}`}
              onClick={() => {
                return exportCsvFn && exportCsvFn();
              }}
            >
              <ExportIcon />
              Export as CSV
            </button>
          </div>
          {isLoading ? (
            <div className={Style.loading_circle_container}>
              <LoadingCircle />
            </div>
          ) : (
            <LocationTree />
          )}
        </div>
      </div>
    </ScreenFrame>
  );
};

PackageTrackPageFeature.defaultProps = {
  initializeHook: () => {}
};

PackageTrackPageFeature.propTypes = {
  initializeHook: PropTypes.func
};

export default () => {
  return (
    <PackageTrackPageConfigContextProvider>
      <PackageTrackPageDataSourceContextProvider>
        <PackageTrackPageStateContextProvider>
          <PackageTrackPageFeature initializeHook={usePackageTrackPageInitializationHook} />
        </PackageTrackPageStateContextProvider>
      </PackageTrackPageDataSourceContextProvider>
    </PackageTrackPageConfigContextProvider>
  );
};
