import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { Link } from "react-router-dom";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";
import SearchBarAndResults from "components/SearchBarAndResultsElastic";
import { getLogo } from "../../common/Utilities";
import HeaderComponentStyle from "./HeaderComponent.module.css";
import { useSoftCacheService } from "../../services/soft-cache-service";
import { LocalCacheService } from "../../services/local-cache-service";
import { SessionStorageService } from "../../services/session-storage-service";
import { useAuthenticationContext } from "../../context/authentication-context/AuthenticationContext";
import { ReactComponent as HelpIcon } from "../../img/help.svg";
import GlobalSearch from "../../features/global-search";

const HeaderComponent = ({ containerStyle, fullScreenMode }) => {
  const [username, setUsername] = useState("guest");
  const [customerLogo, setCustomerLogo] = useState(null);
  const softCacheService = useSoftCacheService();
  const [imageAlt, setImageAlt] = useState(undefined);
  const { signOut } = useAuthenticationContext();

  useEffect(() => {
    const userProfile = LocalCacheService.loadUserProfile();
    if (userProfile) {
      const { profile = {} } = userProfile;
      const uname = profile.firstName || profile.email || username;
      setUsername(uname);
    }
    async function fetchLogo() {
      const logo = await getLogo();
      setCustomerLogo(logo);
    }
    fetchLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UserDropdown = () => {
    return (
      <MDBDropdown>
        <MDBDropdownToggle
          nav
          caret
          id="custom-nav"
          className={HeaderComponentStyle.profile}
          tabIndex={-1}
        >
          <div className={HeaderComponentStyle.profile_name}>{`Hello, ${username}`}</div>
        </MDBDropdownToggle>
        <MDBDropdownMenu className="dropdownmenu">
          <Link to="/profile">
            <MDBDropdownItem>Profile</MDBDropdownItem>
          </Link>
          <a
            href="/login"
            className={HeaderComponentStyle.logout}
          >
            <MDBDropdownItem
              onClick={async () => {
                softCacheService.clearCaches();
                LocalCacheService.clearAllCaches();
                SessionStorageService.clearAllCaches();
                await signOut();
              }}
              className={HeaderComponentStyle.logout}
            >
              Log Out
            </MDBDropdownItem>
          </a>
        </MDBDropdownMenu>
      </MDBDropdown>
    );
  };

  const NotificationDropdown = () => {
    /*
    Temp commenting out until in-app notifications are implemented
    return <NotificationsNoneRoundedIcon fontSize="large" />;
    */
    return null;
  };

  const renderTestingBar = () => {
    return (
      <div className={HeaderComponentStyle.testing_container}>
        Testing Mode
        <Tooltip title={tooltipComponent()}>
          <HelpOutlineIcon />
        </Tooltip>
      </div>
    );
  };

  const tooltipComponent = () => {
    return (
      <div className={HeaderComponentStyle.tooltip_container}>
        <p>What is Testing Mode?</p>
        <p>Testing mode allows you to use the Xemelgo application without affecting your production data</p>
        <p>You can switch between testing and production mode on your profile page</p>
      </div>
    );
  };

  return (
    <div className={containerStyle}>
      {SessionStorageService.getTestMode() === "Test" && renderTestingBar()}
      {!fullScreenMode && (
        <div
          className={
            SessionStorageService.getTestMode() === "Test"
              ? HeaderComponentStyle.main_container_testing
              : HeaderComponentStyle.main_container
          }
        >
          <a
            href="/"
            className={HeaderComponentStyle.home_icon_container}
          >
            <img
              src={customerLogo}
              onLoad={() => {
                const alt = "Unable to load this icon";
                setImageAlt(alt);
              }}
              alt={imageAlt}
              className={HeaderComponentStyle.home_image_style}
            />
          </a>
          <div className={HeaderComponentStyle.search_bar_container}>
            <div className={HeaderComponentStyle.search_bar}>
              <GlobalSearch />
            </div>
          </div>
          <div className={HeaderComponentStyle.user_actions_container}>
            <div className={HeaderComponentStyle.help_icon_wrapper}>
              <div className={HeaderComponentStyle.help_button}>
                <a
                  href="https://docs.xemelgo.com/"
                  target="_blank"
                  role="button"
                  title="Help Center"
                  rel="noreferrer"
                >
                  <HelpIcon className={HeaderComponentStyle.help_icon} />
                </a>
              </div>
            </div>
            <div className={HeaderComponentStyle.user_actions}>
              <NotificationDropdown />
              <UserDropdown />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderComponent;

HeaderComponent.defaultProps = {
  containerStyle: null
};

HeaderComponent.propTypes = {
  containerStyle: PropTypes.string
};
