import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import TrackPageTableGroupHeader from "../../../../components/track-page-table-group-header";
import PaginatedListTableWithTypes from "../../../../../../components/paginated-list-table-with-types";
import useAssetTrackPageDataSourceContext from "../../../../contexts/asset-track-page-data-source-context";
import useAssetTrackPageStateContext from "../../../../contexts/asset-track-page-state-context";
import useDebounce from "../../../../../../hooks/use-debounce";
import Style from "./GroupedAssetListTable.module.css";
import { getValueByType } from "../../../../../../components/paginated-list-table-with-types/utils/get-value-by-type";

export const GroupedAssetListTable = ({ headers, pageSize, groups, selectable, onSelect, isDataLoading }) => {
  const history = useHistory();
  const { locationTreeMap, locationToMetricFieldsMap } = useAssetTrackPageDataSourceContext();
  const {
    expandedLocations,
    locationMetricsLoading,
    locationSortOrder,
    selectedLocationId,
    setExpandedLocations,
    setSelectedLocationId,
    setDisplayedData,
    applySideFilterFn,
    searchInput
  } = useAssetTrackPageStateContext();

  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const searchInputTextDebounced = useDebounce(searchInput, 250);

  const filterStringList = useMemo(() => {
    return searchInputTextDebounced
      .toLowerCase()
      .split(" ")
      .filter((eachString) => {
        return eachString;
      });
  }, [searchInputTextDebounced]);

  const filteredGroups = useMemo(() => {
    let filterApplied = false;
    const filtered = groups.map((group) => {
      const filteredDataList = group.dataList.filter((item) => {
        // Filter results by side filters
        if (!applySideFilterFn(item)) {
          return false;
        }

        if (filterStringList.length === 0) {
          return true;
        }

        // Filter results by search input
        const displayString = headers.reduce((accumulator, eachHeader) => {
          return accumulator + getValueByType(item[eachHeader.id], eachHeader.type, eachHeader.timeFormat, true);
        }, "");

        return filterStringList.some((eachFilterString) => {
          return displayString.toString().toLowerCase().includes(eachFilterString);
        });
      });

      filterApplied = filterApplied || filteredDataList.length !== group.dataList.length;

      return { ...group, dataList: filteredDataList };
    });

    setIsFilterApplied(filterApplied);
    return filtered;
  }, [groups, filterStringList, applySideFilterFn]);

  const sortedFilteredGroups = useMemo(() => {
    const sortedFiltered = filteredGroups.sort((a, b) => {
      const titleA = locationTreeMap[a.id].name;
      const titleB = locationTreeMap[b.id].name;
      switch (locationSortOrder) {
        case "asc":
          return titleA.localeCompare(titleB);
        case "desc":
          return titleB.localeCompare(titleA);
        default:
          if (locationMetricsLoading) {
            return titleB.localeCompare(titleA);
          }
          const fieldA =
            locationToMetricFieldsMap[a.id].find((field) => {
              return field.id === locationSortOrder;
            })?.value || 0;
          const fieldB =
            locationToMetricFieldsMap[b.id].find((field) => {
              return field.id === locationSortOrder;
            })?.value || 0;

          return fieldB - fieldA;
      }
    });

    return sortedFiltered;
  }, [locationToMetricFieldsMap, filteredGroups, locationSortOrder]);

  useEffect(() => {
    setDisplayedData(sortedFilteredGroups);
  }, [sortedFilteredGroups]);

  return (
    <div className={Style.groups_container}>
      {sortedFilteredGroups.map((group) => {
        const { id, dataList } = group;
        return (
          <div key={id}>
            <TrackPageTableGroupHeader
              groupTitle={locationTreeMap[id].name}
              groupFields={isFilterApplied ? [] : locationToMetricFieldsMap[id]}
              fieldsLoading={locationMetricsLoading}
              isGroupClickable={id !== selectedLocationId}
              onGroupClick={() => {
                setSelectedLocationId(id);
              }}
              isExpanded={expandedLocations[id]}
              onExpandClick={(isExpanded) => {
                setExpandedLocations({ ...expandedLocations, [id]: isExpanded });
              }}
            />
            {expandedLocations[id] && (
              <div
                data-cy={`list-table-${id}`}
                className={Style.table_container}
              >
                <PaginatedListTableWithTypes
                  isLoading={isDataLoading && dataList.length === 0}
                  headers={headers}
                  dataList={dataList}
                  getTitleURLCallbackFn={(data) => {
                    return `${history.location.pathname}/detail?itemId=${data.id}`;
                  }}
                  selectable={selectable}
                  onSelectClick={onSelect}
                  numItemsPerPage={pageSize}
                  selectedRowClassName={Style.selected_table_item}
                  paginatorLocation={dataList.length > 0 ? "top" : "none"}
                  paginatorFontWeight={700}
                  showRowsPerPage
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

GroupedAssetListTable.defaultProps = {
  pageSize: 10,
  selectable: false,
  onSelect: () => {},
  isDataLoading: false
};

GroupedAssetListTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      defaultDirection: PropTypes.oneOf(["asc", "desc"]),
      defaultSort: PropTypes.boolean
    })
  ).isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      groupFields: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        })
      ),
      dataList: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          isChecked: PropTypes.bool,
          [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        })
      ).isRequired
    })
  ).isRequired,
  pageSize: PropTypes.number,
  selectable: PropTypes.bool,
  onSelect: PropTypes.func,
  isDataLoading: PropTypes.bool
};
