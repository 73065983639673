import React from "react";
import { TABLE_VIEW_TYPE_ID_MAP } from "../table-control-actions/features/table-view-type-control";
import LocationTable from "./features/location-table";
import ItemTypeTable from "./features/item-type-table";
import ItemTable from "./features/item-table";
import LotTable from "./features/lot-table";
import FilteredItemTable from "./features/filtered-item-table";
import useInventoryTrackPageStateContext from "../../../../contexts/inventory-track-page-state-context";

export const TableDisplay = () => {
  const { selectedViewTypeId, selectedItemTypeIdentifier } = useInventoryTrackPageStateContext();

  if (selectedItemTypeIdentifier) {
    return <FilteredItemTable />;
  }

  switch (selectedViewTypeId) {
    case TABLE_VIEW_TYPE_ID_MAP.location:
      return <LocationTable />;
    case TABLE_VIEW_TYPE_ID_MAP.itemType:
      return <ItemTypeTable />;
    case TABLE_VIEW_TYPE_ID_MAP.item:
      return <ItemTable />;
    case TABLE_VIEW_TYPE_ID_MAP.lot:
      return <LotTable />;
    default:
      return null;
  }
};
