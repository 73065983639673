import React, { useMemo, useEffect } from "react";
import Style from "./TableViewTypeControl.module.css";
import { TABLE_VIEW_TYPE_ID_MAP } from "./data/constants";
import useInventoryTrackPageDataSourceContext from "../../../../../../contexts/inventory-track-page-data-source-context";
import useInventoryTrackPageStateContext from "../../../../../../contexts/inventory-track-page-state-context";
import useInventoryTrackPageConfigContext from "../../../../../../contexts/inventory-track-page-config-context";

export const TableViewTypeControl = () => {
  const {
    selectedLocationId,
    selectedViewModeId,
    selectedViewTypeId,
    setSelectedViewTypeId,
    selectedItemTypeIdentifier,
    setFreeTextSearchInputString,
    setSideFilterValue,
    isSearchParamReady
  } = useInventoryTrackPageStateContext();
  const { locationTreeMap } = useInventoryTrackPageDataSourceContext();

  const { tableViewTypeControl, isLoading: isConfigLoading } = useInventoryTrackPageConfigContext();

  const availableOptions = useMemo(() => {
    const isLeafLocation = locationTreeMap[selectedLocationId]?.childLocations?.length === 1 || false;
    return Object.keys(tableViewTypeControl)
      .sort((aKey, bKey) => {
        return tableViewTypeControl[aKey].index - tableViewTypeControl[bKey].index;
      })
      .map((eachTableViewTypeId) => {
        return { id: eachTableViewTypeId, ...tableViewTypeControl[eachTableViewTypeId] };
      })
      .filter(({ id: eachTableViewTypeId, viewMode, locationCategories }) => {
        // remove the table view type based on view mode in the config
        if (viewMode && !viewMode.includes(selectedViewModeId) && !viewMode.includes("all")) {
          return false;
        }
        // remove the table view type based on the location category you are in
        if (
          locationCategories &&
          !locationCategories.includes(locationTreeMap[selectedLocationId]?.category) &&
          !locationCategories.includes("all")
        ) {
          return false;
        }
        // remove location view type when it's leaf location
        if (eachTableViewTypeId === TABLE_VIEW_TYPE_ID_MAP.location && isLeafLocation) {
          return false;
        }

        // only show item view type when user selected a item type
        if (selectedItemTypeIdentifier && eachTableViewTypeId !== TABLE_VIEW_TYPE_ID_MAP.item) {
          return false;
        }
        return true;
      });
  }, [selectedLocationId, selectedItemTypeIdentifier, locationTreeMap, tableViewTypeControl, selectedViewModeId]);

  useEffect(() => {
    if (
      !isConfigLoading &&
      isSearchParamReady &&
      !availableOptions.find(({ id }) => {
        return id === selectedViewTypeId;
      })
    ) {
      setSelectedViewTypeId(availableOptions?.[0]?.id);
    }
  }, [availableOptions, selectedViewTypeId, isConfigLoading, isSearchParamReady]);

  return (
    <div className={Style.container}>
      <p>View:</p>
      {availableOptions.map(({ id, label }) => {
        return (
          <button
            key={id}
            type="button"
            className={`${Style.button} ${selectedViewTypeId === id && Style.button_selected}`}
            onClick={() => {
              setFreeTextSearchInputString("");
              setSideFilterValue(null);
              setSelectedViewTypeId(id);
            }}
          >
            {label}
          </button>
        );
      })}
    </div>
  );
};
